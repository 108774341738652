import { defineStore } from "pinia";
import {
  type userType,
  store,
  router,
  resetRouter,
  routerArrays,
  storageLocal
} from "../utils";
import { type UserResult, getLogin } from "@/api/user";
import { useMultiTagsStoreHook } from "./multiTags";
import { type DataInfo, setToken, removeToken, userKey } from "@/utils/auth";
import { message } from "@/utils/message";

export const useUserStore = defineStore({
  id: "pure-user",
  state: (): userType => ({
    // 头像
    avatar: storageLocal().getItem<DataInfo<number>>(userKey)?.avatar ?? "",
    // 用户名
    username: "",
    // 昵称
    nickname: storageLocal().getItem<DataInfo<number>>(userKey)?.nickname ?? "",
    // 页面级别权限
    roles: storageLocal().getItem<DataInfo<number>>(userKey)?.roles ?? [],
    // 是否勾选了登录页的免登录
    isRemembered: false,
    // 登录页的免登录存储几天，默认7天
    loginDay: 7,
    token: "",
    userInfo: null,
  }),
  actions: {
    /** 存储头像 */
    SET_AVATAR(avatar: string) {
      this.avatar = avatar;
    },
    /** 存储用户名 */
    SET_USERNAME(username: string) {
      this.username = username;
    },
    /** 存储昵称 */
    SET_NICKNAME(nickname: string) {
      this.nickname = nickname;
    },
    /** 存储角色 */
    SET_ROLES(roles: Array<string>) {
      this.roles = roles;
    },
    /** 存储是否勾选了登录页的免登录 */
    SET_ISREMEMBERED(bool: boolean) {
      this.isRemembered = bool;
    },
    /** 设置登录页的免登录存储几天 */
    SET_LOGINDAY(value: number) {
      this.loginDay = Number(value);
    },
    /** 登入 */
    async loginByUsername(data) {
      return new Promise<UserResult>((resolve, reject) => {
        getLogin(data)
          .then(data => {
            if (data.data.user.identity === 0) {
              message("权限不足：请联系管理员", { type: "error" });
              // 抛出错误
              throw new Error("权限不足");
            }
            const res = {
              data: {
                roles: ["admin"],
                accessToken: data.data.access_token,
                avatar: "https://avatars.githubusercontent.com/u/44761321",
                expires: new Date(),
                nickname: data.data.user.nickname ?? data.data.user.username,
                refreshToken: "eyJhbGciOiJIUzUxMiJ9.adminRefresh",
                username: data.data.user.username,
                password: data.data.user.password
              },
              success: true
            };
            this.token = data.data.access_token;
            if (res?.success) setToken(res.data);
            resolve(res);
            message("登录成功", { type: "success" });
            storageLocal().setItem("userInfo", data.data.user);
            this.userInfo = data.data.user
          })
          .catch(error => {
            reject(error);
            message(`${error.response.data.message}`, { type: "error" });
          });
      });
    },
    /** 前端登出（不调用接口） */
    logOut() {
      this.roles = [];
      removeToken();
      useMultiTagsStoreHook().handleTags("equal", [...routerArrays]);
      resetRouter();
      storageLocal().removeItem("userInfo");
      router.push("/login");
    }
  },
  persist: true
});

export function useUserStoreHook() {
  return useUserStore(store);
}
