export default {
  path: "/company",
  redirect: "/company/index",
  meta: {
    title: "公司管理",
    icon: "fluent:branch-fork-16-filled",
    rank: 1,
    roles: ["1"]
  },
  children: [
    {
      path: "/company/index",
      name: "Company",
      component: () => import("@/views/company/index.vue"),
      meta: {
        title: "公司管理"
      }
    }
  ]
} as RouteConfigsTable;
